// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { theme } from "@flowby/general";

export default extendTheme(
	theme.styles,
	theme.components,
	theme.colors,
	withDefaultColorScheme({
		colorScheme: "teal",
	}),
);
